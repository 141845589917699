import { Link, useFetcher, useParams } from "@remix-run/react";
import { Account } from "./Account";
import { BoardCreate } from "~/routes/boards/components/BoardCreate";
import { cn } from "~/utils/cn";
import { BoardSettings } from "./BoardSettings";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useEffect, useState } from "react";
import { Button } from "~/components/Button";
import { Grip, X } from "lucide-react";
import { useRaisedShadow } from "~/hooks/useRaisedShadow";
import { BoardActions } from "./BoardActions";
import * as Popover from "@radix-ui/react-popover";
import { Emoji } from "~/components/Emoji";
import { NewCountBadge } from "~/components/NewCountBadge";

export type LeftMenuUser = {
  email: string;
  boards: {
    _id: string;
    name: string;
    emojiId?: string | null;
    order: number;
    newAlertsCount: number;
  }[];
};

export const LeftMenu = ({
  user,
  setMenuOpen,
}: {
  user: LeftMenuUser;
  setMenuOpen?: (open: boolean) => void;
}) => {
  const params = useParams();
  const currentBoardId = params.boardId;

  const [boards, setBoards] = useState(user.boards.sort((a, b) => a.order - b.order));
  useEffect(() => {
    setBoards(user.boards.sort((a, b) => a.order - b.order));
  }, [user.boards]);

  const fetcher = useFetcher<any>();

  const board = boards.find((v) => v._id === currentBoardId);

  return (
    <div className="h-full flex flex-col md:items-center 2xl:items-stretch">
      <div className="hidden md:block">
        <Account email={user.email} />
      </div>

      {setMenuOpen && (
        <div className="flex md:hidden items-center justify-between p-3">
          <Account email={user.email} setMenuOpen={setMenuOpen} />
          <Button
            variant={"transparent"}
            className="w-6 h-6 p-0 rounded-full flex items-center justify-center bg-primary-background"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <X className="w-4 h-4 text-gray-300" />
          </Button>
        </div>
      )}

      <hr className="border-secondary/10 w-full -mt-0.5"></hr>
      {board && <BoardActions board={board} />}
      <hr className="border-secondary/10 w-full hidden md:block"></hr>
      <div className="flex items-center justify-between px-2 pt-4 pb-2">
        <div className="text-xs font-bold text-secondary/60 sm:block md:hidden 2xl:block">
          Boards
        </div>
        <BoardCreate setMenuOpen={setMenuOpen} />
      </div>
      <div className="overflow-y-scroll flex-1">
        <Reorder.Group
          axis="y"
          onReorder={(v) => {
            setBoards(v);
            fetcher.submit(
              { _action: "board-order", boardIds: JSON.stringify(v.map((x) => x._id)) },
              { method: "POST" }
            );
          }}
          values={boards}
          className="flex flex-col"
        >
          {boards.map((board) => {
            return (
              <BoardItem
                key={board._id}
                board={board!}
                currentBoardId={currentBoardId}
                setMenuOpen={setMenuOpen}
              />
            );
          })}
        </Reorder.Group>
      </div>

      <hr className="border-secondary/10" />
      <div className="p-3 flex gap-3 items-center">
        <img className="w-6" src={"/assets/images/logo/yellow.png"} alt="" />
        <div className="text-primary text-base font-bold sm:block md:hidden 2xl:block">
          Ninjalerts
        </div>
      </div>
    </div>
  );
};

const BoardItem: React.FC<{
  board: {
    _id: string;
    name: string;
    emojiId?: string | null | undefined;
    order: number;
    newAlertsCount: number;
  };
  currentBoardId?: string;
  setMenuOpen?: (open: boolean) => void;
}> = ({ board, currentBoardId, setMenuOpen }) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Reorder.Item
      value={board}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
    >
      {board._id === currentBoardId ? (
        <div className="border-l-4 pl-2 group/parent relative p-3 flex items-center justify-between bg-yellow-300/20 border-l-yellow-400 cursor-default h-12">
          <div className="hidden md:block 2xl:hidden">
            <Popover.Root>
              <Popover.Trigger asChild>
                <div className="flex items-center justify-start gap-2 cursor-pointer">
                  <Emoji emojiId={board.emojiId} />
                </div>
              </Popover.Trigger>
              <Popover.Content
                className="bg-stone-600 relative rounded-xl text-sm py-3 z-10 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade"
                align="center"
                side="right"
                sideOffset={15}
              >
                <div className="px-3 flex justify-between items-center min-w-[170px]">
                  <div className="text-secondary text-xs">{board.name}</div>
                  <div className="flex gap-1 ml-6">
                    <BoardSettings board={board} setMenuOpen={setMenuOpen} />
                    <Button
                      variant={"transparent"}
                      size={"icon"}
                      className={cn(
                        isDragging ? "cursor-grabbing" : "cursor-grab",
                        "p-2 -my-1 touch-none "
                      )}
                      onPointerDown={(event) => dragControls.start(event)}
                    >
                      <Grip className="w-4 h-4 text-gray-200" />
                    </Button>
                  </div>
                </div>
              </Popover.Content>
            </Popover.Root>
          </div>

          <div className="flex items-center justify-between gap-2 flex-grow md:hidden 2xl:flex">
            <Emoji emojiId={board.emojiId} />
            <div className="w-[calc(100vw-200px)] md:w-[70px] text-md md:text-xs font-medium text-secondary relative text-ellipsis truncate flex-grow">
              {board.name}
            </div>

            <div className="flex">
              <BoardSettings board={board} setMenuOpen={setMenuOpen} />
              <Button
                variant={"transparent"}
                size={"icon"}
                className={cn(
                  isDragging ? "cursor-grabbing" : "cursor-grab",
                  "p-2 -my-1 touch-none"
                )}
                onPointerDown={(event) => dragControls.start(event)}
              >
                <Grip className="w-4 h-4 text-gray-200" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Link
          to={`/boards/${board._id}`}
          key={board._id}
          className="group/parent w-full flex items-center justify-between p-3 pr-[18px] cursor-pointer h-12"
          onClick={() => {
            setMenuOpen ? setMenuOpen(false) : "";
          }}
        >
          <div className="flex items-center justify-start gap-2">
            <Emoji emojiId={board.emojiId} />
            <div className="w-[calc(100vw-100px)] md:w-[120px] text-md md:text-xs font-medium text-secondary relative text-ellipsis truncate sm:block md:hidden 2xl:block">
              {board.name}
            </div>
          </div>
          <NewCountBadge count={board.newAlertsCount} />
        </Link>
      )}
    </Reorder.Item>
  );
};
